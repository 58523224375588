import { useLocation, useNavigate } from 'react-router-dom';

import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';
import {
  ApiUser,
  ApiUserEnvironment,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { EmailSignupForm } from 'components/Email/EmailSignupForm';
import { EmailSignupFormValues } from 'components/Email/types/EmailSignupFormValues';
import { FormUsageContext } from 'components/Form/Form';
import { FormSubmitFunction } from 'components/Form/FormContext';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { updateUserEnvironment } from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { NonNullableFields } from 'types/NonNullableFields';
import { HttpError } from 'utils/HttpError';
import { trackSignUp } from 'utils/analytics/track/trackSignUp';
import { configureTrackJs } from 'utils/configureTrackJs';
import { getThirdPartyLoginUrl } from 'utils/getThirdPartyLoginUrl';
import { postJson } from 'utils/http/postJson';
import { resetRecaptcha } from 'utils/recaptcha';
import {
  Action,
  generateRedirectUrl,
  saveTemporaryStorage,
} from 'utils/temporaryStorage';
import { showModal } from 'zustand-stores/modalStore';

type Props = {
  analyticsTitle: string;
  usageContext: FormUsageContext;
  redirectTo?: string | null | undefined;
  redirectUrl?: string | null | undefined;
  savedSearch?: ApiSavedSearchUnsaved;
  savedListing?: { id: string; type: string; name: string } | undefined;
  cmsSubscribe?: CmsApiSubscribe;
  subscribedSubsite: string | null | undefined;
  onSignup?: () => void;
  onLogin?: ((arg0: ApiUser) => void) | undefined;
  loginOverrideFn?: () => void;
  compactView?: boolean;
  hideLoginBlock?: boolean;
  customButtonText?: string;
  setEmailInUse?: (emailInUse: string) => void;
  requirePassword?: boolean;
};

export function EmailSignupFormContainer({
  analyticsTitle,
  usageContext,
  redirectTo,
  redirectUrl,
  cmsSubscribe,
  savedSearch,
  savedListing,
  subscribedSubsite,
  onSignup,
  onLogin,
  loginOverrideFn,
  compactView,
  hideLoginBlock,
  customButtonText,
  setEmailInUse,
  requirePassword,
}: Props) {
  const initialValues = {
    email: '',
    recaptchaKey: 0,
    gRecaptchaResponse: undefined,
  };

  const dispatch = useMainDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const showLoginModal = (email?: string, initialWarning?: string) => {
    showModal('LOGIN', {
      redirectTo: redirectUrl || redirectTo,
      savedListing,
      cmsSubscribe,
      savedSearch,
      subscribedSubsite,
      email,
      onLogin,
      initialWarning,
    });
  };

  const getLoginUrlAndState = (email?: string) => ({
    to: '/login',
    state: {
      redirectTo: redirectUrl || redirectTo,
      cmsSubscribe,
      savedListing,
      savedSearch,
      subscribedSubsite,
      email,
    },
  });

  const handleThirdPartyLoginUrl = (
    provider: 'facebook' | 'google' | 'apple',
  ) =>
    getThirdPartyLoginUrl({
      provider,
      redirectTo:
        redirectTo || redirectUrl || `${location.pathname}${location.search}`,
      savedListing,
      savedSearch,
      subscribedSubsite,
    });

  const onSubmit: FormSubmitFunction<EmailSignupFormValues> = async ({
    values,
    handleError,
    updateSubmitting,
  }) => {
    const { email, recaptchaKey, gRecaptchaResponse, password } = values;

    const actions: Action[] = [];

    if (savedListing) {
      actions.push({ type: 'SAVE_LISTING', data: savedListing });
    }

    if (savedSearch) {
      actions.push({ type: 'SAVE_SEARCH', data: savedSearch });
    }

    if (cmsSubscribe) {
      actions.push({ type: 'CMS_SUBSCRIBE', data: cmsSubscribe });
    }

    if (subscribedSubsite) {
      actions.push({
        type: 'SUBSCRIBE_SUBSITE',
        data: { subscribedSubsiteId: subscribedSubsite },
      });
    }

    if (redirectUrl) {
      actions.push({ type: 'SIGNUP_BLOCK_REDIRECT', data: { redirectUrl } });
    }

    try {
      let id = null;
      if (actions.length > 0) {
        id = await saveTemporaryStorage(actions);
      }
      const endpoint = requirePassword
        ? '/data/website/signup-email-password'
        : '/data/website/signup-email';
      const userEnvironment = await postJson<
        NonNullableFields<ApiUserEnvironment>
      >(endpoint, {
        email,
        password,
        gRecaptchaResponse,
        redirectTo: generateRedirectUrl(
          redirectTo ||
            `${location.pathname}${location.search}${location.hash}`,
          id,
        ),
      });
      trackSignUp(userEnvironment.user, 'Email');
      configureTrackJs(userEnvironment.user?.id);
      dispatch(updateUserEnvironment(userEnvironment));

      if (onSignup) {
        onSignup();
      }

      updateSubmitting(false);

      if (redirectTo) {
        navigate(redirectTo);
      }
    } catch (error) {
      if (error instanceof HttpError) {
        const errorArray = error.responseJson?.errors;
        const emailInUseError = errorArray?.find(
          (e) => e.name === 'email' && e.type === 'alreadyInUse',
        );

        if (emailInUseError) {
          if (setEmailInUse) {
            setEmailInUse(email);
          } else {
            showLoginModal(
              email,
              /* TRANSLATORS: Login modal error message */
              getText(
                'An account with this email already exists. Did you mean to login?',
              ),
            );
          }
        } else {
          resetRecaptcha();
          handleError(error, {
            email,
            recaptchaKey: recaptchaKey + 1,
            gRecaptchaResponse: undefined,
          });
        }

        updateSubmitting(false);
      }
    }
  };

  return (
    <EmailSignupForm
      analyticsTitle={analyticsTitle}
      usageContext={usageContext}
      initialValues={initialValues}
      showLoginModal={showLoginModal}
      getLoginUrlAndState={getLoginUrlAndState}
      getThirdPartyLoginUrl={handleThirdPartyLoginUrl}
      loginOverrideFn={loginOverrideFn}
      onSubmit={onSubmit}
      compactView={compactView}
      hideLoginBlock={hideLoginBlock}
      customButtonText={customButtonText}
      requirePassword={requirePassword}
    />
  );
}
